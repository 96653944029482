import React from 'react'
import PropTypes from 'prop-types'
import { Box, Heading, Paragraph } from 'grommet'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { Layout } from '../layout'
import SEO from '../components/seo'

const Section = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'heading heading'
    '${props => props.dir}';
  gap: 20px;
  margin-bottom: 60px;
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-areas:'heading' 'aside' 'article';
  }
`
const Article = styled.article`
  grid-area: article;
  li {
    font-size: 0.825em;
    margin-bottom: 0.5rem;
  }
`
const Aside = styled.aside`
  grid-area: aside;
`

const labsDesc = [
  {
    title: 'Laboratorium nano-inżynierii genetycznej',
    subTitle: '(sala 106)',
    text:
      'Badania prowadzone w laboratorium nano-inżynierii genetycznej, dotyczą wykorzystania sekwencji microRNA jako informacji genetycznej, dostarczanej za pomocą nanopłatków grafenowych oraz nanocząstek metali do komórek nowotworowych, w warunkach in vitro. Laboratorium działa zgodnie z Dz.U.2019.0.706 t.j. - Ustawa z dnia 22 czerwca 2001 r. o mikroorganizmach i organizmach genetycznie zmodyfikowanych.',
    equipment: [
      'elektroporator GenexPulser (Bio-Rad)',
      'komora laminarna (ESCO)',
      'inkubator CO2 ICO105med (Memmert)',
      'komora klimatyczna ICH110 (Memmert)',
      'mikroskop świetlny z kamerą i system wizualizacji/ archiwizacji próbek (Leica)',
      'autoklaw z zestawem aparatury kontrolno-pomiarowej (Prestige Medical)',
      'wirówka laboratoryjna (MPW)',
    ],
    image: '106',
  },
  {
    title: 'Laboratorium inżynierii tkankowej',
    subTitle: '(sala 107)',
    text:
      'W laboratorium inżynierii tkankowej prowadzone są badania nad tworzeniem bio-nano-struktur tkanki mięśniowej, mięśnia sercowego, wątroby i innych, w oparciu o biomimiczne matrix zewnątrzkomórkowe, komórki progenitorowe, pochodzące z hodowli pierwotnej i komórki pochodzące z linii komórkowych.',
    equipment: [
      'aparat do pomiaru przemiany tlenowej Micro-Oxymax (Columbus Instruments)',
      'automatyczny licznik komórek TC20™ (Bio-Rad)',
      'mikroskop świetlny odwrócony z kontrastem Hoffmana DMi8 z kamerą MC190 HD, z oprogramowaniem Las V4.10 (Leica)',
      'inkubator z CO2 możliwościa regulacji składu powietrza, (Memmert)',
      'łaźnia wodna (Memmert) ',
      'komora laminarna',
    ],
    image: '107',
  },
  {
    title: 'Laboratorium analizy kwasów nukleinowych',
    subTitle: '(sala 118)',
    text:
      'W laboratorium przeprowadzane są analizy kwasów nukleinowych (RNA, DNA) izolowanych z komórek prokariotycznych i eukariotycznych. W laboratorium znajdują się 3 stanowiska badawcze: 1. Stanowisko do izolacji RNA i DNA z materiału biologicznego (hodowle komórkowe, tkanki); 2. Stanowisko do reakcji odwrotnej transkrypcji – syntezy cDNA na matrycy mRNA (RT-PCR); 3. Stanowisko do badania ekspresji wybranych genów za pomocą ilościowego PCR w czasie rzeczywistym (Real-Time PCR, qPCR).',
    equipment: [
      'termocykler 2720 Thermal Cycler (Applied Biosystems) ',
      'termocykler StepOne Real-Time PCR System (Applied Biosystems) ',
      'termoblok Dry Block Thermostat Bio TDB-100 (Biosan)',
    ],
    image: '118',
  },
  {
    title: 'Laboratorium nanobiotechnologii komórki nowotworowej',
    subTitle: '(sala 119)',
    text:
      'Laboratorium przystosowane jest do wyprowadzenia ustabilizowanych linii komórkowych z tkanek, w tym z bioptatów tkanek nowotworowych oraz prowadzenia hodowli komórek in vitro w systemie 2D i 3D, a także do przygotowania i przeprowadzania testów komórkowych określających wpływ nanostruktur na funkcjonowanie komórek nowotworowych oraz wyjaśniających mechanizmy ich działania.',
    equipment: [
      'inkubator CO2 INCOMED153 (Memmert)',
      'komora laminarna AIRSTREAM Klasa II biohazard (ESCO)',
      'dysocjator gentleMACS wraz z blokiem magnetycznym QuadroMACS Separator (Miltenyi Biotec)',
      'łaźnia wodna  WNB10 (Memmert)',
      'mikroskop optyczny odwrócony Nikon Eclipse 90i wraz z kamerą i systemem do obrazowania i archiwizacji dokumentacji',
    ],
    image: '119',
  },
  {
    title:
      'Laboratorium hodowli komórkowych i oceny funkcjonalności żywieniowej',
    subTitle: '(sala 120)',
    text:
      'Pracownia przeznaczona jest do prowadzenia hodowli komórkowych oraz doświadczeń in vitro z liniami nowotworowymi, liniami unieśmiertelnionymi oraz czynnikami umiarkowanego ryzyka. Ponadto, w laboratorium prowadzi się badania modelowe dotyczące żywieniowego oddziaływania związków bioaktywnych pochodzenia roślinnego i zwierzęcego z uwzględnieniem stanowisk do ekstrakcji, oceny składu ekstraktu i mechanizmów działania ekstraktów.',
    equipment: [
      'komora laminarna BSC II ESCO Airstream (ESCO)',
      'komora laminarna BSC II Bio Air TopSafe 1.2 (Bio Air Industires)',
      'inkubator z CO2 (Memmert)',
      'mikroskop odwrócony Olympus CKX 1 zintergrowany z kamerą ProgResC12 plus (Jenoptik Jena) i lampą U-RFLT50 do mikroskopii fluorescencyjnej (Olympus)',
      'wytrząsarka do płytek PMS-1000i (Grant-bio)',
      'łaźnia wodna (Memmert)',
    ],
    image: '120',
  },
  {
    title: 'Laboratorium cytobiochemii',
    subTitle: '',
    text:
      'W laboratorium cytobiochemii przeprowadzane są analizy przemian molekularnych komórek i tkanek. W laboratorium przeprowadzane są badania zmian syntezy białek, aktywności szlaków sygnałowych, produkcji reaktywnych form tlenu, aktywności fizjologicznej i biochemicznej komórek. W analizach wykorzystywane są liczne techniki laboratoryjne: metoda Western blot, testy immunoenzymatyczne, testy płytkowe i testy biochemiczne.',
    equipment: [
      'czytnik płytek  Infinite M200 (Tecan)',
      'system dokumentacji s C400 (Azure biosystem)',
      'system Western blot (Bio-Rad)',
      'aparatura do homogenizacji i regulacji temperatury próbek (Qiagen, Biosan)',
    ],
    image: '121',
  },
  {
    title: 'Laboratorium mikroskopii konfokalnej',
    subTitle: ' ',
    text:
      'Laboratorium mikroskopii konfokalnej przeznaczone jest do obrazowania zmian fizjologii komórek. Laboratorium specjalizuje się w obrazowaniu interakcji nanocząstek z komórkami oraz w analizie kolokalizacji. W laboratorium mikroskopii konfokalnej przeprowadza się badania na preparatach utrwalonych oraz wielogodzinne badania przyżyciowe.',
    equipment: [
      'mikroskop FV1000 (Olympus) wyposażony w trzy moduły laserowe, obiektywy achromatyczne i komorę do przyżyciowego obrazowania komórek;',
      'kriostat CM1900 (Leica)',
    ],
    image: 'confocal',
  },
  {
    title: 'Laboratorium ekologii doświadczalnej',
    subTitle: ' ',
    text:
      'W laboratorium prowadzona jest hodowla mikroorganizmów pochodzących z banku patogenów oraz izolacja i hodowla mikroorganizmów środowiskowych, w tym również potencjalnie patogennych. Badania z ich wykorzystaniem opierają się na tworzeniu i modyfikacji nanokompozytów i nanostruktur o działaniu antymikrobiologicznym. W laboratorium przeprowadzane są również analizy ekotoksykologiczne z wykorzystaniem organizmów wskaźnikowych: pierwotniaków, glonów, wybranych roślin wodnych.',
    equipment: [
      'inkubator mikrobiologiczny AC2-4E8 (Memmert) z regulacją temperatury i wilgotności',
      'inkubator rotacyjny Shaking incubator SI500',
      'komora laminarna II klasy (ESCO)',
      'zestaw akwariów do hodowli wodnych organizmów modelowych (rzęsa wodna, pierwotniaki, glony)',
      'zestaw lamp umożliwiający wzrost roślin wodnych',
    ],
    image: '127',
  },
  {
    title: 'Laboratorium nanobiotechnologii',
    subTitle: '(sala 117)',
    text:
      'Badania prowadzone w laboratorium dotyczą syntez nanocząstek metali, nanostruktur węglowych, liposomów, oraz innych biomolekuł. Wykonywane są również w nim pomiary wielkości i stabilności nanocząstek. W laboratorium znajduje się także stanowisko do analizy makrostruktury i mikrostruktury próbek biologicznych w tym tkanek pochodzenia zwierzęcego i roślinnego.',
    equipment: [
      'mikroskop stereoskopowy model SZX10 z kamerą i systemem CellD software version 3.1 (Olympus)',
      'analizator wielkości nanocząstek, potencjału zeta i masy cząsteczkowej Zetasizer Nano (Malvern)',
    ],
    image: '117',
  },
  {
    title:
      'Laboratorium metabolomiki i fizjologii mikroorganizmów (pracownia studencka)',
    subTitle: '(sala 126)',
    text:
      'W laboratorium prowadzone są podstawowe badania biochemiczne z zakresu oceny markerów metabolomu komórki zwierzęcej, a także markerów podstawowych funkcji fizjologicznych mikroorganizmów.',
    equipment: [
      'mikroskop optyczny Leica DM750 (Leica Microsystems)',
      'inkubator z regulacją CO2 NU-5820E (NuAire)',
    ],
    image: '126',
  },
  {
    title: 'Laboratorium bioinżynierii (pracownia studencka)',
    subTitle: '(pracownia studencka) (sala 102)',
    text:
      'Laboratorium przeznaczone jest do pracy z materiałem biologicznym oraz innymi materiałami wymagającymi przygotowania w warunkach sterylnych, m.in. do izolacji komórek i tkanek, zakładania hodowli pierwotnych, doświadczeń in ovo (przygotowanie modeli na błonie kosmówkowo-omoczniowej zarodków kury), przygotowania roztworów, podłoży, nano- i biomateriałów.',
    equipment: [
      'dwie komory laminarne Alpina K1600 (Alpina) ',
      'mikroskop odwrócony Oxion Inverso (Euromex) ',
      'inkubator z CO2 GalaxyR+ (RS Biotech)',
      'łaźnia wodna z programatorem LW102 (AJL electronic) ',
      'automatyczny licznik komórek TC20 (Bio-Rad) ',
      'wirówka laboratoryjna MPW-350R (MPW MedicalInsruments)',
      'mini-wirówka MSC-6000 (Biosan)',
    ],
    image: '102',
  },
]

const LabSection = ({ content, image, dir }) => (
  <Section dir={dir}>
    <Heading level={3} fill gridArea="heading" margin="none">
      {content.title}
    </Heading>
    <Article>
      <Paragraph fill>{content.text}</Paragraph>
      <Heading level={4} margin={{ bottom: 'small' }}>
        Wyposażenie
      </Heading>
      <ul>
        {content.equipment.map((eq, idx) => (
          <li key={`equipment-${idx}`}>{eq}</li>
        ))}
      </ul>
    </Article>
    <Aside>{image}</Aside>
  </Section>
)

LabSection.propTypes = {
  content: PropTypes.object.isRequired,
  image: PropTypes.node,
  dir: PropTypes.string,
}

const LabsPage = () => {
  const queryImg = useStaticQuery(graphql`
    query {
      _102: file(relativePath: { eq: "102.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      _106: file(relativePath: { eq: "106.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      _107: file(relativePath: { eq: "107.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      _117: file(relativePath: { eq: "117.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      _118: file(relativePath: { eq: "118.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      _119: file(relativePath: { eq: "119.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      _120: file(relativePath: { eq: "120.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      _121: file(relativePath: { eq: "121.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      _126: file(relativePath: { eq: "126.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      _127: file(relativePath: { eq: "127.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      confocal: file(relativePath: { eq: "konfokal.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)
  const images = {
    '106': <Img fluid={queryImg._106.childImageSharp.fluid} />,
    '107': <Img fluid={queryImg._107.childImageSharp.fluid} />,
    '118': <Img fluid={queryImg._118.childImageSharp.fluid} />,
    '119': <Img fluid={queryImg._119.childImageSharp.fluid} />,
    '120': <Img fluid={queryImg._120.childImageSharp.fluid} />,
    '121': <Img fluid={queryImg._121.childImageSharp.fluid} />,
    '126': <Img fluid={queryImg._126.childImageSharp.fluid} />,
    '127': <Img fluid={queryImg._127.childImageSharp.fluid} />,
    confocal: <Img fluid={queryImg.confocal.childImageSharp.fluid} />,
    '117': <Img fluid={queryImg._117.childImageSharp.fluid} />,
    '102': <Img fluid={queryImg._102.childImageSharp.fluid} />,
  }
  return (
    <Layout>
      <SEO title="Laboratoria" />
      <Heading>Laboratoria</Heading>
      <Box as="article" width="large">
        <Paragraph margin={{ bottom: 'large' }} fill>
          W Katedrze znajduje się 9 w pełni wyposażonych laboratoriów o
          różnorodnym charakterze, 2 pracownie studenckie, zwierzętarnia
          (umożliwiająca przeprowadzenie eksperymentów na zwierzętach
          doświadczalnych takich jak myszy, szczury), a także ogólne
          pomieszczenie techniczne wykorzystywane m.in. jako zmywalnia i
          sterylizatornia.
        </Paragraph>
      </Box>
      {labsDesc.map((lab, idx) => (
        <LabSection
          key={`lab${idx}`}
          content={lab}
          image={images[lab.image] ? images[lab.image] : images['102']}
          dir={idx % 2 === 0 ? 'aside article' : 'article aside'}
        />
      ))}
    </Layout>
  )
}

export default LabsPage
